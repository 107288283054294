import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Grid } from '@material-ui/core'
import CustomizedTextField from '../CustomizedTextField';
import axios from 'axios';
import { getAPIUrl, handleAxiosError } from '../AppUtils';

const styles = theme => ({
    textField: {
        width: 200,
    },
    button: {
        marginLeft: 10,
        width: 50,
        color: theme.palette.text.primary,
    }
});

const EventNameInput = React.memo((props)=>{
    const { classes, initialName, setEventNameEditable, eventId } = props
    const [eventName,setEventName] = useState(initialName);
    // useEffect(()=>{
    //     setEventName(initialName)
    // },[initialName])
    function handleChange(e){
        setEventName(e.target.value)
    }
    function saveEventName(){
        axios.post(getAPIUrl()+`/saveEventName/${eventId}`,{name:eventName})
        .catch(error=>handleAxiosError(error))
        setEventNameEditable(false);
    }
    return (
        <React.Fragment>
            <CustomizedTextField
                className={classes.textField}
                variant="outlined"
                value={eventName}
                onChange={handleChange}
                autoFocus
            />
            <Button className={classes.button} variant="contained" color="secondary" size="small"
                onClick={()=>saveEventName(eventName)}
            >
                Save
            </Button>
        </React.Fragment>
    )
}) 

export default withStyles(styles)(EventNameInput);