import axios from 'axios';
export function loginAuth(){
    localStorage.removeItem('intelhsApiToken');
    axios.post(getAPIUrl() + '/authURL', { 'hostname': window.location.hostname, 'redirect': window.location.origin })
    .then(response => {
        // console.log(response)
        window.location.href = response.data
    })
    .catch(error=>handleAxiosError(error));
}
export function handleAxiosError(error){
    if(axios.isCancel(error)){
        return 'cancel';
    }
    else{
        if (error.response && error.response.status === 401) {
            loginAuth();
        }
        return true;
    }
}
export function getAPIUrl(){
    if(window.location.hostname.split('.')[0]==="staging"){
        return "https://www.intelhs.com:20000";
    }
    else if(window.location.hostname.split('.')[0]==="demo"){
        return "https://www.intelhs.com:20001";
    }
    else if(window.location.hostname.split('.')[0]==="ucsd-test"){
        return "https://www.intelhs.com:20003";
    }
    else if(window.location.hostname.split('.')[0]==="ucsd"){
        return "https://www.intelhs.com:20004";
    }
    else if(window.location.hostname.split('.')[0]==="test"){
        return "https://www.intelhs.com:20005";
    }
    return process.env.REACT_APP_API_URL;
}
export function getSocketUrl(){
    if(window.location.hostname.split('.')[0]==="staging"){
        return "https://www.intelhs.com:22000";
    }
    else if(window.location.hostname.split('.')[0]==="demo"){
        return "https://www.intelhs.com:22001";
    }
    else if(window.location.hostname.split('.')[0]==="ucsd-test"){
        return "https://www.intelhs.com:22003";
    }
    else if(window.location.hostname.split('.')[0]==="ucsd"){
        return "https://www.intelhs.com:22004";
    }
    else if(window.location.hostname.split('.')[0]==="test"){
        return "https://www.intelhs.com:22005";
    }
    return process.env.REACT_APP_SOCKET_URL;
}