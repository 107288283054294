import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import axios from 'axios';
import { getAPIUrl, handleAxiosError } from '../AppUtils';

const styles = theme => ({
    button: {
        boxShadow: 'none',
        color: theme.palette.text.primary,
    }
});

function ConfirmCloseEventDialog(props) {
    const { classes, open, handleClose, eventId } = props;
    function closeEvent(){
        axios.post(getAPIUrl()+`/closeEvent/${eventId}`)
        .catch(error=>handleAxiosError(error))
        handleClose();
    }
    return (
        <Dialog 
            open={open}
            onClose={handleClose}
            fullWidth
        >
            <DialogTitle>{'Confirmation Message'}</DialogTitle>
            <DialogContent>
                Are you sure you want to close the current event?
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={handleClose} variant="contained" color="default">
                    Back
                </Button>
                <Button className={classes.button} onClick={()=>closeEvent()} variant="contained" color="secondary">
                    Close Event
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withStyles(styles)(ConfirmCloseEventDialog);