import React from 'react'
import { useNewTimer } from './CustomHooks';
import { format } from 'date-fns'
import { Typography } from '@material-ui/core'

function TimeDisplay(){
    
    const timer = useNewTimer(new Date());
    return (
        <Typography variant="body1">{format(timer,"h:mm:ss A / D MMM YYYY [[]ddd[]]")}</Typography>
    )
}
export default TimeDisplay;