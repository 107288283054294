import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import { Table, TableHead, TableRow, TableBody, TableCell, Link, Typography, IconButton,
        Tooltip, TableSortLabel, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Pagination from 'react-bootstrap/Pagination'
import './custom.scss';
import { format } from 'date-fns'

const styles = theme => ({
    tableHeaderCell: {
        minWidth: 120,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: "bold",
        backgroundColor: theme.palette.secondary.main,
        padding: 0,
        '&:last-child': {
          paddingRight: 0,
        },
    },
    tableHeaderRow: {
        height: 30,
    },
    tableRow: {
        height: 40,
        '&:hover': {
            cursor: "pointer",
        },
    },
    tableCell: {
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: theme.palette.secondary.main,
        padding: "4px, 24px, 4px, 24px",
    },
    searchGrid: {
        marginBottom: 20,
    },
    totalText: {
        marginLeft: 15,
    },
    pagination:{
        marginTop: 30,
    }
})
function SortableLabel(props){
    return(
        <Tooltip
            title="Sort"
            placement='bottom-end'
            enterDelay={300}
        >
            <TableSortLabel
                active={props.active}
                hideSortIcon
                direction={props.order}
                onClick={props.handleSort}
                IconComponent={(props) => <FontAwesomeIcon {...props} size="lg" icon={["fa","caret-down"]}/>}
            >
                {props.label}
            </TableSortLabel>
        </Tooltip>
    )
}

function ReportsTable(props) {
    const { classes, events } = props
    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(0);
    const rowsPerPage = 10;
    const handleRequestSort = property => event => {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    function stableSort(array, cmp) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      return stabilizedThis.map(el => el[0]);
    }
    function getSorting(order, orderBy) {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
    function desc(a, b, orderBy) {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
    function handlePageChange(pageNum){
        setPage(pageNum);
    }
    const filteredEvents = events?events:[]
    const numPages = Math.ceil(filteredEvents.length/rowsPerPage);
    function handlePageIncrease(){
        if(page<numPages-1){
            setPage(page+1);
        }
    }
    function handlePageDecrease(){
        if(page>0){
            setPage(page-1);
        }
    }
    function handleEventClick(id){
        props.history.push(`/event/${id}`)
    }
    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow className={classes.tableHeaderRow}>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="id"}
                                handleSort={handleRequestSort("id")}
                                label="Event ID"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="name"}
                                handleSort={handleRequestSort("name")}
                                label="Name"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="status"}
                                handleSort={handleRequestSort("status")}
                                label="Status"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="create_time"}
                                handleSort={handleRequestSort("create_time")}
                                label="Create Time"
                            />
                        </TableCell>
                        <TableCell className={classes.tableHeaderCell} align="center">
                            <SortableLabel
                                order={order}
                                active={orderBy==="close_time"}
                                handleSort={handleRequestSort("close_time")}
                                label="Close Time"
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(filteredEvents, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(event=>
                    <TableRow className={classes.tableRow} key={event.id} onClick={()=>handleEventClick(event.id)}>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{event.id}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{event.name}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{event.status?'Open':'Closed'}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{format(event.create_time,'MMM DD, YYYY hh:mm A')}</Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="center">
                            <Typography>{event.close_time?format(event.close_time,'MMM DD, YYYY hh:mm A'):''}</Typography>
                        </TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
            <div>
            <Pagination className={classes.pagination}>
                <Pagination.First onClick={()=>handlePageChange(0)}/>
                <Pagination.Prev onClick={handlePageDecrease} disabled={page===0}/>
                {page-10>0?<Pagination.Ellipsis/>:undefined}
                {Array(numPages).fill().map((_, i) => {
                    if(i>page-10 && i<page+10){
                        return(
                            <Pagination.Item key={i} 
                                active={page===i} 
                                onClick={()=>handlePageChange(i)}
                            >
                                {i+1}
                            </Pagination.Item>
                        );
                    }
                })}
                {page+10<numPages-1?<Pagination.Ellipsis/>:undefined}
                <Pagination.Next onClick={handlePageIncrease} disabled={page>=numPages-1}/>
                <Pagination.Last onClick={()=>handlePageChange(numPages-1)}/>
            </Pagination>
            </div>
        </div>
    )
} 

export default withStyles(styles)(ReportsTable);