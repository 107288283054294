import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios';
import EventsTable from './EventsTable';
import { handleAxiosError, getAPIUrl } from './AppUtils';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    }
});

function EventsPage(props) {
    const { classes } = props;
    const source = axios.CancelToken.source();
    const [events, setEvents] = useState(undefined);

    useEffect(() => {
        getEvents();
        return () => {source.cancel("unmount");}
    }, []);
    function getEvents(){
        axios.get(getAPIUrl()+'/events',{cancelToken: source.token})
        .then(response => {
            setEvents(response.data);
        })
        .catch(error=>handleAxiosError(error));
    }
    return (
        <div className={classes.page}>
            <EventsTable
                events={events}
                history={props.history}
            />
        </div>
    );
}

export default withStyles(styles)(EventsPage);