import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, CircularProgress } from '@material-ui/core'
import axios from 'axios';
import EventDetails from './EventDetails';
import io from 'socket.io-client';
import { handleAxiosError, getAPIUrl, getSocketUrl } from '../AppUtils';

const styles = theme => ({
    page: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 50,
        paddingRight: 50,
        minHeight: 300,
    }
});


class EventDetailsPage extends React.Component {
    
    constructor(props) {
        super(props);
        this.socket = io(getSocketUrl());
        this.state = {
            eventDetails: undefined,
            loadingEvent: true,
            filters: [],
            departments: [],
            eventSubmissionStatus: {departments:[],columns:[]},
            eventStaffing: {staffing:[],allTitles:[]},
            eventBedAvailability: {types:[],statuses:[],availability:[]},
            eventTechnologyStatus: {items:[],technologyStatuses:[],status:[]},
            eventResourceStatus: {items:[],status:[]},
            eventTRAIN: undefined,
            eventImpact: {departments:[],columns:[]},
            eventProblems: {departments:[],columns:[]},
            eventUrgentNeed: {departments:[],columns:[]},
            userHospital: {}
        }
    }
    updateUser = (msg) => {
        // console.log("update user called");
        // console.log(msg);
        if (msg == "Update the Users"){
            this.getEvent();
        }
    }
    getUserHospital = () => {
        axios.get(getAPIUrl()+'/userHospital',{cancelToken: this.source.token})
        .then(response => {
            this.setState({
                userHospital: response.data
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getFilters = () => {
        axios.get(getAPIUrl()+'/eventAggregation',{cancelToken: this.source.token})
        .then(response => {
            this.setState({
                filters: response.data.filters,
                departments: response.data.departments,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getEvent = () => {
        this.getEventDetails();
        this.getSubmissionStatus();
        this.getStaffing();
        this.getBedAvailability();
        this.getTechnologyStatus();
        this.getResourceStatus();
        this.getTRAIN();
        this.getEventImpact();
        this.getEventProblems();
        this.getEventUrgentNeed();
    }
    getEventDetails = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/eventDetails/${match.params.id}`
            :getAPIUrl()+'/eventDetails'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventDetails: response.data,
                loadingEvent: false,
            });
        })
        .catch(error => {
            if(handleAxiosError(error)!=='cancel'){
                this.setState({
                    loadingEvent: false,
                });
            };
        });
    }
    getSubmissionStatus = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/submissionStatus`
            :getAPIUrl()+'/event/submissionStatus'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventSubmissionStatus: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getStaffing = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/staffing`
            :getAPIUrl()+'/event/staffing'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            // console.log(response.data)
            this.setState({
                eventStaffing: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getBedAvailability = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/available-beds`
            :getAPIUrl()+'/event/available-beds'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventBedAvailability: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getTRAIN = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/TRAIN`
            :getAPIUrl()+'/event/TRAIN'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventTRAIN: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getTechnologyStatus = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/technologyStatus`
            :getAPIUrl()+'/event/technologyStatus'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventTechnologyStatus: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getResourceStatus = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/resourceStatus`
            :getAPIUrl()+'/event/resourceStatus'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventResourceStatus: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getEventImpact = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/impact`
            :getAPIUrl()+'/event/impact'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventImpact: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getEventProblems = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/problems`
            :getAPIUrl()+'/event/problems'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventProblems: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    getEventUrgentNeed = () => {
        const { match } = this.props;
        const url = match.params.id?
            getAPIUrl()+`/event/${match.params.id}/urgentNeed`
            :getAPIUrl()+'/event/urgentNeed'
        axios.get(url,{headers: { Pragma: 'no-cache'},cancelToken: this.source.token})
        .then(response => {
            this.setState({
                eventUrgentNeed: response.data,
            });
        })
        .catch(error=>handleAxiosError(error));
    }
    componentDidMount() {
        this.source = axios.CancelToken.source()
        if(this.props.user.type===2){
            this.getUserHospital();
        }
        else{
            this.getFilters();
        }
        this.getEvent();
    }
    componentDidUpdate(prevProps, prevState){
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.getEvent();
        }
        if(this.state.eventDetails !== prevState.eventDetails){
            this.socket.off('socket_msg', this.updateUser)
            if(this.state.eventDetails && this.state.eventDetails.status===1){
                this.socket.on('socket_msg', this.updateUser)
            }
            else{
            }
        }
    }
    componentWillUnmount() {
        this.source.cancel("unmount");
        this.socket.close();
    }
    render(){
        const { classes, match } = this.props;
        const { eventDetails, loadingEvent, filters, eventStaffing, eventBedAvailability, 
            eventTechnologyStatus, eventResourceStatus, eventSubmissionStatus, userHospital, 
            eventTRAIN, eventImpact, eventProblems, eventUrgentNeed, departments } = this.state
        return (
            <div className={classes.page}>
                {loadingEvent?
                    <Grid container justify='center' alignItems='center'>
                        <CircularProgress/>
                    </Grid>
                    :eventDetails?
                        <EventDetails
                            eventId={match.params.id}
                            singleHospital={this.props.user.type===2}
                            userHospital={userHospital}
                            eventDetails={eventDetails}
                            filters={filters}
                            departments={departments}
                            eventSubmissionStatus={eventSubmissionStatus}
                            eventStaffing={eventStaffing}
                            eventBedAvailability={eventBedAvailability}
                            eventTechnologyStatus={eventTechnologyStatus}
                            eventResourceStatus={eventResourceStatus}
                            eventTRAIN={eventTRAIN}
                            eventImpact={eventImpact}
                            eventProblems={eventProblems}
                            eventUrgentNeed={eventUrgentNeed}
                        />
                    :<Grid container justify='center' alignItems='center'>
                        <Typography className={classes.text} variant="h4">
                            Event Not Found
                        </Typography>
                    </Grid>
                }
            </div>
        );
    }
}

export default withStyles(styles)(EventDetailsPage);